/* @import url('https://fonts.googleapis.com/css2?family=Open Sans:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
/* .makeStyles-toolbar-94{
  background: #272525;
  height:50px !important;
}
.makeStyles-titleSpan-98 {
  color: white;
  display: block;
  padding: 5px 25px 5px 25px !important;
  font-size: 30px;
  background: #55a630 !important;
}
.makeStyles-saveIcon-96 {
  font-size: 40px;
  color: #777777;
}
.makeStyles-addBoxIcon-95 {
  font-size: 40px;
  color: #777777 !important;
  fill:#777777 !important
} */
/* /* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.dropdown-toggle::after {
  display: none;
}

/* .react-emoji{
  height:28px;
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
}
.MuiInput-underline:not(.Mui-disabled):before {
  border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
}
.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 0px solid #2d2d2d !important;
  pointer-events: none;
}
.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.fb_iframe_widget {
  display: none !important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
