.listmain{
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
    margin-bottom:0px;
    background:#EEEEEE;
    padding:10px;
    border-radius: 10px;
    border: 1px solid rgba(119,119,119,.50);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.04);

}
.listmainunread{
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
    margin-bottom:0px;
    background:white;
    padding:10px;
    border-radius: 10px;
    border: 1px solid rgba(119,119,119,.50);
    box-shadow: 0px 1px 3px 2px rgba(0,0,0,0.04);
}

/* .dropdown{
    display:none;
} */

.listmain:hover .dropdown{
    display:block;
}


.listItem:hover{
    background:white !important;
}