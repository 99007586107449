.RRT__container {
  position: relative;
  max-height: 30px;

}

/****************************/
/******** tab styles ********/
/****************************/
.RRT__tabs {
  display: flex;
  flex-wrap: wrap;
}

.RRT__accordion {
  flex-direction: column;
}

.RRT__tab {
  margin-left: 4px;    
    /* border-width: 1px 1px 1px 0; */
    cursor: pointer;
    z-index: 1;
    white-space: nowrap;
    padding: 3px 3px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 11px;
}

.RRT__tab_1 {
  background: rgba(33,213,155,.1);
  color:rgb(33,213,155);
}
.RRT__tab_2 {
  background:rgba(227,77,89,.1);
  color:rgb(227,77,89);
}
.RRT__tab_3 {
  background: rgba(87,184,255,.1);
  color:rgb(87,184,255);
}

.RRT__tab_4 {
  background: rgba(255,199,0,.1);
  color:rgb(255,199,0);
}

.RRT__tab_5 {
  background: rgba(249,150,0,.1);
  color:rgb(249,150,0);
}
.RRT__tab_6 {
  background: rgba(90, 96, 127,.1);
  color:rgb(90, 96, 127);
}
.RRT__tab_7 {
  background: rgba(213, 33, 159,.1);
  color:rgb(213, 33, 159);
}
.RRT__tab_8 {
  background: rgba(0, 82, 217,.1);
  color:rgb(0, 82, 217);
}
.RRT__tab:focus {
  outline: 0;
  
}

.RRT__accordion .RRT__tab {
  border-left-width: 1px;
}

.RRT__tab--first {
  /* border-left-width: 1px; */
}

.RRT__tab--selected {
  /* background: #fff; */
  
}

.RRT__tab--selected:focus {
  
}

.RRT__tab--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.RRT__tab:focus {

}

.RRT__tab .RRT__removable {
  display: flex;
  align-items: center;
}

.RRT__tab .RRT__removable-text {
  margin-right: 10px;
}

.RRT__tab .RRT__removable-icon {

  
display: none;
  font-size: 14px;
  color: white;
  margin-right: 3px;
  width: 16px;
  height: 20px;
  text-align: center;
  margin-top: -5px;
  background: #f50057;
}
.RRT__tab .RRT__removable-icon:hover{
  display:none;
  background: #9e043a;
}
/****************************/
/********* panel styles *****/
/****************************/
.RRT__panel {
  margin-top: -1px;
  padding: 1em;
  border: 1px solid #ddd;
}

.RRT__panel--hidden {
  display: none;
}

.RRT__accordion .RRT__panel {
  margin-top: 0;
}

/****************************/
/******* showmore control ***/
/****************************/
.RRT__showmore {
  background: rgb(220 217 217);
  border: 1px solid #ddd;
  cursor: pointer;
  z-index: 10;
  white-space: nowrap;
  margin-left: 4px;
  position: relative;
}

.RRT__showmore--selected {
  background: rgb(220 217 217);
  border-bottom: none;
}

.RRT__showmore-label {
  padding:4px;
  /* background: rgb(220 217 217); */
  color:#7d7d7d;;
  position: relative;
  bottom: -2px;
  z-index: 1;
  font-size: 12px;
}

.RRT__showmore-label--selected {
  /* background-color: #eee; */
}

.RRT__showmore-list {
  position: absolute;
  right: -1px;
  z-index: 10000 !important;
  top: 100%;
  display: block;
}

.RRT__showmore-list--opened {
  display: block;
}
.MuiTooltip-tooltip{
  background:white !important;
  box-shadow: 0px 0px 1px 1px rgba(0,0,0,.2);
  color:black !important;
}
/****************************/
/********** inkbar **********/
/****************************/
.RRT__inkbar-wrapper {
  width: 100%;
}

.RRT__inkbar {
  position: relative;
  bottom: 0;
  height: 2px;
  margin-top: -2px;
  background-color: deepskyblue;
  transition: left 800ms cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 2;
}
