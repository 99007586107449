#root  {
  background-color: #E6D9EC;
  font-family: 'Poppins';
  height: 100%;
  overflow: auto;

}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins" !important;
}

input {
  font-family: "Poppins" !important;
}
input:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%) !important;
  /* border-color: transparent; */
}

p {
  font-family: "Poppins" !important;
}

table,
thead,
----* tbody,
tr,
td {
  font-family: "Poppins";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.MuiListItemText-primary {
  font-size: 12px !important;
}
.MuiListItem-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.zIndex-10000 {
  z-index: 10000 !important;
}
.display-block {
  display: block !important;
}
.px-6 {
  padding-right: 5rem !important ;
  padding-left: 5rem !important ;
}

.vh-100 {
  height: 100vh;
}

.fit-content {
  width: fit-content;
}
.font-xssmall {
  font-size: 9px;
}
.font-xsmall {
  font-size: 11px !important;
}
.font-small {
  font-size: 13px !important;
}
.font-medium {
  font-size: 15px;
}
.font-large {
  font-size: 18px;
}
.max-w-650 {
  max-width: 650px !important;
}
.dot {
  height: 10px;
  width: 10px;
}
.cursor-pointer {
  cursor: pointer;
}

.poppins {
  font-family: "Poppins";
}

.form-control:focus {
  outline: none !important;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%) !important;
  border-color: transparent !important;
}

.first-character-display {
  padding: 7px 9px;
}

div.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
}
