section{
    width: 96%;
    margin:0 auto;
   
}
.message{
    display:flex;
    padding:15px;
    margin:20px 15px;
    width: fit-content;
    border-radius: "5px 5px 0px 5px",
}
.message.incoming{
    /* background:lightgrey; */
    background: #ECF0F3;
    color: #4E4E4E;
}
.message.outgoing{
    /* background:purple; */
    background-color: #4D1277;
    color: #fff;
    /* color:white; */
    margin-left: auto;
    position: relative;
  
}
/* .message.label:before{
    content:'';
    width:90%;
    height:2px;
    background:black;
    position: absolute;
   margin-left: 0;
   left:60px;
   top:30px; 

} */
.message.label{
    display: block;
   
    padding-top:0px;
    padding-bottom:0px;

    text-align: center;
    width:50%;
    margin:0 auto;
   border-radius: 0;
    border-bottom: 2px solid black;

}
.seen{
    
    align-self: flex-end;
    color:black;
    position: absolute;
    bottom:-19px;
    right:10px;
    cursor:pointer;
}