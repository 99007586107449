.splitter-layout {
   /* position: absolute;*/
    display: flex;
    flex-direction: row;
    width: 100%;   
    overflow: hidden;
  }
  
  .splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: hidden;
    background: white;
  }
  .splitter-layout   .collapse-button-container{
    height: 20px;
    background-color: #66c047;
    cursor: pointer;
  }
  .splitter-layout   .collapse-button-container .button{
    color: white; 
    height: auto; 
    width: auto;
  }
  .splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
  }
  
  .splitter-layout > .layout-splitter {
    flex: 0 0 auto;


    cursor: col-resize;
    background-color: #949494;
  }
  
  .splitter-layout .layout-splitter:hover {
    background-color: #bbb;
  }
  
  .splitter-layout.layout-changing {
    cursor: col-resize;
  }
  
  .splitter-layout.layout-changing > .layout-splitter {
    background-color: #aaa;
  }
  
  .splitter-layout.splitter-layout-vertical {
    flex-direction: column;
  }
  
  .splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
  }
  
  .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
  }