.activeclass{
    background:rgb(220, 222, 224) !important;
  }

  .dropdownlistitem:hover{
    background-color:rgb(220, 222, 224) !important;
  }
  .MuiSelect-select {
    cursor: pointer;
    min-width: 200px !important;

    user-select: none;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  width: 170px !important;
}
.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.loader-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.loader-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}